import './App.css';
import { AuthProvider } from '../contexts/AuthContext';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Lander from './Lander/Lander'


function App() {
  return (
      <div className="App">
        <BrowserRouter>
          <AuthProvider>
              <Routes>
                  <Route path= '/' element={<Lander/>}></Route>       
                </Routes>   
          </AuthProvider>
        </BrowserRouter>
      </div>
    
  );

}

export default App;
