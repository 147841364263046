import "./Lander.css"
import "./Signup.css"
import { FaXTwitter } from "react-icons/fa6";
import {useAuth} from '../../contexts/AuthContext'
import { FaTelegram } from "react-icons/fa";
import { useRef, useState} from "react"

const Lander = () => {
    const [message, setMessage] = useState("")
    const emailRef = useRef()
    const { login, logout} = useAuth()
    const [error, setError] = useState([])
    const [loading, setLoading] = useState(false)

    async function handleSubmit(e) {
        console.log('handle submit')
        e.preventDefault() 
        try {
            setError([])
            setLoading(true)
            setMessage("")
            await logout()
            await login(emailRef.current.value, "PLACEHOLDER")
            setMessage("Thank You for signing up!")
            await logout()
        } catch(err) {
            console.error(err.message)
            if (err.message.includes("invalid-email")){
                setError(["invalid email"])
            } else {
                setError(["Failed to Sing Up"])
         }
        }
        setLoading(false)
    }

    return ( 
        <div className="background">
        <div className= "board">
            <span className="GlowingTitle">Goonsesh.com</span>
        </div>
        <span className = "GlowingText">Coomming Soon</span>
        <div className="wrapper" style={{marginTop:"3rem"}}>
            <form onSubmit={handleSubmit}>
                <h1>Newsletter</h1>
                <div style={{margin:"1rem 0 2rem 0"}}>Let's keep in touch to change the world of gooning forever</div>
                {error && error.length > 0&& <div className='display-error'>{error.map((item) => (<div>{item}</div>))}</div>}

                {message && <div className='display-message'>{message}</div>}

                {!message && <div className="input-box">
                    <input type="text" placeholder="Email" ref={emailRef} required/>
                </div>}
                
                {!message && <button disabled={loading} type=" " className="btn">Join the Sesh</button>}
                <div className="socials" style={{marginTop:"1rem"}}>
                    <a href="https://www.x.com/goonseshdotcom"><FaXTwitter style={{color:"white", fontSize:"2.5rem", margin:"1rem"}}/></a>
                    <a href="https://t.me/+KLzTDJkIWTVjZGZi"><FaTelegram style={{color:"white", fontSize:"2.5rem", margin:"1rem"}}/></a>
                </div>
            </form>
        </div>
        </div>
     );
}
 
export default Lander;
